<template>
  <div><h1 class="heading">Подведение итогов</h1></div>

  <transition name="modal">
    <modal v-if="showModal" @close="showModal = false">

      <modal-header>Информация об аккаунте </modal-header>

      <modal-body-left>
        <template v-for="item in summarizingDataResult.level_information" :key="item.id">
            <template v-if="item.current && item.current_level !== 'Новичок'">
                <span class="level-info-heading">Для подтверждения уровня {{item.name}}</span>
            </template>
            <template v-else>
                <div class="divider"></div>
                <span class="level-info-heading">Для перехода на уровень {{item.name}}</span>
            </template>

          <template v-for="(itemDetail, conditionIndex) in item.conditions" :key="itemDetail.id">
            <div class="mb-3">

<!--              <div class="mb-2">{{item.name}}</div>-->
              <div class="detail-info-progress">
                <div class="detail-info-progress-text">

                    <template v-if="itemDetail.you_lack_points !== 0">
                        еще {{itemDetail.you_lack_points}} из {{itemDetail.yourGoalPoint}} <span class="material-icons star-icon-small">star</span>
                    </template>
                    <template v-else>
                        {{itemDetail.yourGoalPoint}} из {{itemDetail.yourGoalPoint}} <span class="material-icons star-icon-small">star</span>
                    </template>

                </div>
              </div>
              <div class="detail-info-progress">
                <div class="detail-info-progress-text">

                    <template v-if="itemDetail.you_lack">
                        еще {{itemDetail.you_lack}} из {{itemDetail.yourGoalTms}} торговых марок
                    </template>
                    <template v-else>
                        {{itemDetail.yourGoalTms}} из {{itemDetail.yourGoalTms}} торговых марок
                    </template>


                </div>
              </div>
            </div>

              <div class="level-info-heading" v-if="conditionIndex+1 < item.conditions.length && item.conditions.length > 1">
                  Или
              </div>

          </template>


        </template>
      </modal-body-left>

    </modal>
  </transition>


  <div class="primary-block">
    <div class="row">
      <div class="col-lg-4 primary-block-content">
        <div>Начислено за квартал:</div>
        <div class="scores quarter-scores">{{ summarizingDataResult.earned_points_this_month }} <span class="material-icons star-icon">star</span></div>

<!--        <div>-->
<!--          <div class="need-scores">Для перехода на след. уровень: {{ summarizingDataResult.lack_points_for_activation }} <tooltip tooltip="Если не набрать всю сумму, то в конце месяца баллы сгорят" position="right" class="tooltip-secondary pl-2"></tooltip></div>-->
<!--          <div class="progress">-->
<!--            <div class="progress-bar" :style="{ width: summarizingDataResult.current_percentage+'%'}"></div>-->
<!--          </div>-->
<!--        </div>-->

        <div class="link-to-history">
          <router-link to="/sale/history/">История продаж <span class="material-icons">arrow_right_alt</span></router-link>
        </div>
      </div>
      <div class="col-lg-4 primary-block-content">
        <div class="d-flex">Доступно к списанию: <tooltip tooltip="Начисляются по итогам месяца" position="right" class="tooltip-secondary"></tooltip></div>
        <div class="scores">
          {{ summarizingDataResult.active_points }} <span class="material-icons star-icon">star</span><template v-if="summarizingDataResult.active_points < 0"><tooltip tooltip="Баланс отрицательный потому что есть возвраты" position="right" class="tooltip-secondary minus-balance"></tooltip></template>
        </div>

      </div>
      <div class="col-lg-4 primary-block-content">

          <div>Уровень в {{ summarizingDataResult.quarter }} квартале:</div>
          <div class="level level-desktop">
              {{ summarizingDataResult.current_level }}
            <span class="material-icons chevron-icon">expand_more</span>
            <div class="detail-info" v-if="summarizingDataResult.level_information != undefined">

                <template v-for="item in summarizingDataResult.level_information" :key="item.id">
                    <template v-if="item.current">
                        <span class="level-info-heading">Для подтверждения уровня {{item.name}}</span>
                    </template>
                    <template v-else>
                        <div class="divider" v-if="item.name !== 'Новичок'"></div>
                        <span class="level-info-heading">Для перехода на уровень {{item.name}}</span>
                    </template>
                    <template v-for="(itemDetail, conditionIndex) in item.conditions" :key="itemDetail.id">
                        <div class="mb-3">

                            <div class="detail-info-progress">
                                <div class="detail-info-progress-bar">
                                    <div class="progress">
                                        <div class="progress-bar pinky-color" :style="{width: itemDetail.percentage_points+'%'}"></div>
                                    </div>
                                </div>
                                <div class="detail-info-progress-text">

                                    <template v-if="itemDetail.you_lack_points !== 0">
                                        еще {{itemDetail.you_lack_points}} из {{itemDetail.yourGoalPoint}} <span class="material-icons star-icon-small">star</span>
                                    </template>
                                    <template v-else>
                                        {{itemDetail.yourGoalPoint}} из {{itemDetail.yourGoalPoint}} <span class="material-icons star-icon-small">star</span>
                                    </template>
                                </div>
                            </div>
                            <div class="detail-info-progress">
                                <div class="detail-info-progress-bar">
                                    <div class="progress">
                                        <div class="progress-bar pinky-color" :style="{ width: itemDetail.percentage_tms+'%'}"></div>
                                    </div>
                                </div>
                                <div class="detail-info-progress-text">

                                    <template v-if="itemDetail.you_lack">
                                        еще {{itemDetail.you_lack}} из {{itemDetail.yourGoalTms}} торговых марок
                                    </template>
                                    <template v-else>
                                        {{itemDetail.yourGoalTms}} из {{itemDetail.yourGoalTms}} торговых марок
                                    </template>

                                </div>
                            </div>
                        </div>

                        <div class="level-info-heading" v-if="conditionIndex+1 < item.conditions.length && item.conditions.length > 1">
                            Или
                        </div>

                    </template>

                </template>

            </div>

          </div>
          <div class="level level-mobile" @click="showModal = true">
            {{ summarizingDataResult.current_level }}
            <span class="material-icons chevron-icon">expand_more</span>
          </div>
      </div>
    </div>
  </div>

    <template v-if="!summarizingData.length">
        <div class="date-selector">
            <div class="pagination">Расчетный период:</div>
            <button class="change-month" @click="previousMonth" :disabled="!canGoPrev">
                <span class="material-icons pagination-icon-left">arrow_circle_left</span>
            </button>
            <div class="quarter" v-if="summarizingData.current_position != undefined">{{summarizingData.current_position.quarter}} квартал {{summarizingData.current_position.year}}</div>
            <button class="change-month" @click="nextMonth" :disabled="!canGoNext">
                <span class="material-icons pagination-icon-right">arrow_circle_right</span>
            </button>

        </div>
        <notify-primary class="mb-4">Информация добавляется по итогам квартала</notify-primary>
        <div class="information-block">
            <div class="information-block-primary">
                <div>Заработано баллов в {{summarizingData.third_name}}:</div>
                <div>{{summarizingData.earned_third_month}}</div>
            </div>
            <div class="information-block-secondary">
                <div>Зачислено активных баллов в {{summarizingData.third_name}}:</div>
                <div>{{summarizingData.recieved_third_month}}</div>
            </div>
        </div>

        <div class="information-block">
            <div class="information-block-primary">
                <div>Заработано баллов в {{summarizingData.second_name}}:</div>
                <div>{{summarizingData.earned_second_month}}</div>
            </div>
            <div class="information-block-secondary">
                <div>Зачислено активных баллов в {{summarizingData.second_name}}:</div>
                <div>{{summarizingData.recieved_second_month}}</div>
            </div>
        </div>

        <div class="information-block">
            <div class="information-block-primary">
                <div>Заработано баллов в {{summarizingData.first_name}}:</div>
                <div>{{summarizingData.earned_first_month}}</div>
            </div>
            <div class="information-block-secondary">
                <div>Зачислено активных баллов в {{summarizingData.first_name}}:</div>
                <div>{{summarizingData.recieved_first_month}}</div>
            </div>
        </div>

        <div class="information-block" v-if="summarizingData.current_position != undefined">
            <div class="information-block-primary">
                <div>Итого за {{summarizingData.current_position.quarter}} квратал заработано баллов:</div>
                <div>{{ summarizingData.recieved_per_whole_quarter }}</div>
            </div>
        </div>

        <div class="information-block" v-if="summarizingData.current_position != undefined">
            <div class="information-block-primary">
                <div>Количество проданных торговых  марок за {{summarizingData.current_position.quarter}} квартал</div>
                <div>{{ summarizingData.sold_tm_amount }} шт.</div>
            </div>
        </div>

        <div class="information-block" v-if="summarizingData.current_position != undefined">
            <div class="information-block-primary">
                <div>По итогам {{summarizingData.current_position.quarter}} квартала присвоен уровень</div>
                <div>{{ summarizingData.achieved_level }}</div>
            </div>
        </div>
    </template>

    <template v-else>
        Здесь будут отображаться квартальные итоги
    </template>

  <secondary-button @click="$router.push({ name: 'SaleAdd' })" class="mt-5">
    <div class="d-flex align-items-center justify-content-center">
      Заработать баллы <span class="material-icons pl-2">east</span>
    </div>
  </secondary-button>

</template>

<script>
export default {
  name: "Summarizing",
  data () {
    return {
      example1: 35,
      example2: 10,
      example3: 20,
      example4: 30,
      example5: 40,
      example6: 50,
      example7: 60,
      example8: 70,
      example9: 80,
      showModal: false,
        disabled: false
    }
  },
    mounted() {
        let today = new Date();
        let quarter = Math.floor((today.getMonth() + 3) / 3);
        this.$store.dispatch("summarizingData", {
            quarter : quarter,
            year : today.getFullYear()
        });
        this.$store.dispatch("summarizingDataResult", {});
    },
    methods: {
        previousMonth() {
            this.$store.dispatch("summarizingData", {
                quarter : this.summarizingData.prev_step.quarter,
                year : this.summarizingData.prev_step.year
            });
        },
        nextMonth() {
            this.$store.dispatch("summarizingData", {
                quarter : this.summarizingData.next_step.quarter,
                year : this.summarizingData.next_step.year
            });
        },
    },

    computed: {
        summarizingData() {
            return this.$store.getters.getSummarizingData;
        },
        summarizingDataResult() {
            return this.$store.getters.getSummarizingDataResult;
        },
        canGoPrev(){
            return this.summarizingData.can_go_prev;
        },
        canGoNext(){
            return this.summarizingData.can_go_next;
        }


    },
}
</script>

<style scoped>

.modal-body .level-info-heading{
    margin-bottom: 10px;
}

.modal-body .divider{
    margin-top: 0px;
    margin-bottom: 10px;
}

.modal-body > .divider:first-of-type{
    display: none;
}

.detail-info > .divider:first-of-type{
    display: none;
}

.level-desktop {
    display:block;
    margin-top: 8px;
}
.level-mobile {
    display:none;
}
.tooltip-color {
  color:red;
}
.primary-block {
  color:#FFFFFF;
  background: linear-gradient(135deg, #537CFF 0%, #3663F2 100%);
  border-radius: 8px;
  padding:20px 30px;
  margin-top:50px;
  margin-bottom:50px;
}
.quarter-scores {
    margin-top: 8px;
}
.scores {
  font-weight: bold;
  font-size: 25px;
  display:flex;
  align-items:center;
}
.star-icon {
  color:#ffc107;
  font-size:24px;
  margin-left: 5px;
}
.star-icon-small {
  color: #ffc107;
  font-size: 18px;
  margin-left: 5px;
}
.link-to-history {
  margin-top:30px;
}
.link-to-history a {
  color:#ffffff;
  display: flex;
}
.chevron-icon {
  color:#ffffff;
  font-size:30px;
  top: 6px;
  position: relative;
}
.level {
  font-weight: bold;
  font-size: 28px;
  font-style: normal;
  letter-spacing: 1.5px;
  position: relative;
}
.level-info-heading {
    font-weight: bold;
    font-size: 16px;
    color: #424242;
}
.level:after {
  content: '';
  height:4px;
  width:45px;
  border-radius:8px;
  background: #FF6B93;
  position:absolute;
  bottom:-5px;
  left:0px;
  z-index:1;
}
.need-scores {
  font-size:13px;
  margin-bottom:5px;
  display: flex;
  align-items: center;
}
.progress {
  width: 160px;
  height: 6px;
  border-radius: 9px;
}
.progress-bar {
  background-color: #2CC5BD !important;
  border-radius: 9px;
}
.date-selector {
  display:flex;
  flex-direction: row;
  align-items:center;
  height:30px;
  margin-bottom:20px;
}
.pagination {
  font-weight: bold;
  font-size: 16px;
  margin-right:20px;
}
.quarter {
  font-weight: bold;
  font-size: 16px;
  color: #3663F2;
    width: 150px;
    text-align: center;

}
.pagination-icon-left, .pagination-icon-right {
    color:#94a4c4;
    font-size:34px;
    outline:none;
    transition: 0.1s ease;
}
.pagination-icon-left:active, .pagination-icon-right:active {
    color: #3e5585;
}
.change-month {
    background-color: transparent;
    border: none;
}

.change-month:disabled{
    cursor: not-allowed;
}

.change-month:disabled > .pagination-icon-left, .change-month:disabled > .pagination-icon-right{
    opacity: 0.4;
}

.information-block {
  width:100%;
  background: #F7F7F7;
  border-radius: 9px;
  padding:15px;
  margin-bottom:15px;
}
.information-block-primary {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #171A1F;
}
.information-block-secondary {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #71747A;
}
.detail-info {
  display:none;
  position:absolute;
  background: #FFFFFF;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.08), 0px 0px 48px rgba(0, 0, 0, 0.04);
  border-radius: 9px;
  z-index:999;
  min-width:600px;
  padding:20px 40px;
  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0;
  font-size:14px;
  color: #71747A;
  left:-450px;
}
.level:hover > .detail-info {
  display: block;
}
.detail-info-progress {
  display:flex;
  align-items:center;
  width:100%;
}
.detail-info-progress-bar {
  margin-right:30px;
  margin-top: 6px;
}
.detail-info-progress-bar > .progress {
  width: 280px;
}
.detail-info-progress-bar > .progress > .progress-bar{
  width: 280px;
}
.detail-info-progress-text {
  font-size: 16px;
  color: #171A1F;
  display: flex;
  align-items: center;
}
.pinky-color {
  background-color: #FF6B93 !important;
  border-radius: 9px;
}
.magenta-color {
  background-color: #9585F0 !important;
  border-radius: 9px;
}
.divider {
  font-weight: bold;
  font-size: 16px;
  color: #171A1F;
  margin: 30px 0;
}
.divider:after {
  content: "";
  display: inline-block;
  height: 10px;
  vertical-align: bottom;
  width: 520px;
  margin-right: -100%;
  border-top: 4px solid #DEE8FD;
}
.divider-mobile {
    font-weight: bold;
    font-size: 16px;
    color: #171A1F;
    margin: 30px 0;
}
.divider-mobile:after {
    content: "";
    display: inline-block;
    height: 10px;
    vertical-align: bottom;
    width: 150px;
    margin-right: -100%;
    margin-left: 10px;
    border-top: 2px solid #DEE8FD;
}
.minus-balance {
  font-size:18px;
  font-weight:normal;
}

@media (max-width: 820px) {
    .primary-block-content {
        margin-bottom:60px;
    }
    .primary-block-content:last-of-type {
        margin-bottom:20px;
    }
    .quarter {
        text-align:center;
    }
    .level:hover > .detail-info {
        display: none;
    }
    .level-desktop {
        display:none;
    }
    .level-mobile {
        display:block;
    }
    .primary-block-content {
      margin-bottom:60px;
    }
    .primary-block-content:last-of-type {
      margin-bottom:20px;
    }
  .divider:after {
    content: "";
    display: inline-block;
    height: 10px;
    vertical-align: bottom;
    width: 100px;
    margin-right: -100%;
    margin-left: 10px;
    border-top: 2px solid #DEE8FD;
  }
}
</style>
